import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../assets/logo.png'
import style from './style/navbar.module.scss'
import { IoArrowRedoSharp } from "react-icons/io5";

export default function Navbar() {
  const [activeLowerComponent, setActiveLowerComponent] = useState<'menu' | null>(null);

  const toggleMenu = () => {
    setActiveLowerComponent(activeLowerComponent === 'menu' ? null : 'menu');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (activeLowerComponent === 'menu') {
        setActiveLowerComponent(null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeLowerComponent]);

  return (
    <>
      <div className={style.desktop}>
        <div className={style.upper}>
          <div className={style.logo}>
            <img src={Logo} />
          </div>

          <div className={style.links}>
            <NavLink to='/'>Home</NavLink>
            <a href='/#about'>Über uns</a>
            <NavLink to='/services'>Leistungen</NavLink>
            
          </div>

          <NavLink className={style.contact} to='/contact'>Kontakt</NavLink>
        </div>
      </div>

      <div className={style.mobile}>
        <div className={style.upper}>
          <div className={style.logo}>
            <img src={Logo} />
          </div>

          <div className={style.buttons}>
            <NavLink className={style.contact} to='/contact'><IoArrowRedoSharp /></NavLink>
            

            <div className={style.openMenu} onClick={toggleMenu}>
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>

        <div className={style.lower}>
          {activeLowerComponent === 'menu' && (
            <div className={style.menu}>
              <NavLink to='/'>Home</NavLink>
              <a href='/#about'>Über uns</a>
              <NavLink to='/services'>Leistungen</NavLink>
              <NavLink to='/#faq'>FAQ</NavLink>
            </div>
          )}
        </div>
      </div>
    </>
  )
}