import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Entry from './pages/entry';
import RootLayout from './layout';
import Services from './pages/services';
import Contact from './pages/contact';
import ServiceDetail from './pages/servicesDetail';
import Imprint from './pages/imprint';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Entry />} />
          <Route path='services' element={<Services />} />
          <Route path='services/:serviceName' element={<ServiceDetail />} />
          <Route path='contact' element={<Contact />} />
          <Route path='imprint' element={<Imprint />} />
          <Route path="*" element={<Navigate to="/" />} /> 
        </Route>
      </Routes>
    </Router>
  );
}
