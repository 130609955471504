import React from 'react'
import style from './style/contact.module.scss'
import { IoBusinessOutline, IoCallOutline, IoMailOutline, IoTimeOutline } from 'react-icons/io5'

export default function Contact() {
    const email = 'joseph.gala@web.de';

  return (
    <div className={style.container}>
        <div className={style.heading}>
            <h1>Kontakieren Sie uns</h1>
        </div>

        <div className={style.informations}>
            <div className={style.wrapper}>
                <div className={style.block}>
                    <IoMailOutline />
                    <p>{email}</p>
                </div>
                <div className={style.block}>
                    <IoCallOutline />
                    <p>+49-174-9143638</p>
                </div>
                <div className={style.block}>
                    <IoBusinessOutline />
                    <p>Am Pulverl 14<br/>85051 Ingolstadt<br/>Germany</p>
                </div>
                <div className={style.block}>
                    <IoTimeOutline />
                    <p>09:00 - 18:00</p>
                </div>
            </div>

            <div className={style.cta}>
                <a href={`mailto:${email}`}>Anschreiben</a>
            </div>
        </div>

        <div className={style.howItWorks}>
            <div className={style.wrapper}>
                <div className={style.step}>
                    <div className={style.indicator}>
                        <span>1</span>
                    </div>
                    <div className={style.content}>
                        <h2>Senden Sie uns eine kurze E-Mail mit den Details, die Sie für wichtig halten.</h2>
                    </div>
                </div>

                <div className={style.step}>
                    <div className={style.indicator}>
                        <span>2</span>
                    </div>
                    <div className={style.content}>
                        <h2>Wir antworten Ihnen umgehend mit Informationen und möglichen Terminen.</h2>
                    </div>
                </div>

                <div className={style.step}>
                    <div className={style.indicator}>
                        <span>3</span>
                    </div>
                    <div className={style.content}>
                        <h2>Vereinbaren Sie einen Termin und lassen Sie uns die Arbeit erledigen.</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
