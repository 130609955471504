import React from 'react'
import styles from './style/imprint.module.scss'

function Imprint() {
  return (
    <div className={styles.container}>
        <div className={styles.heading}>
            <h1>Impressum</h1> 
        </div>
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h2>Angaben gemaß § 5 TMG</h2>
                <p>Anthony Joseph</p>
                <p>Am Pulverl 14</p>
                <p>85051 Ingolstadt</p>
            </div>
            <div className={styles.block}>
                <h2>Kontakt</h2>
                <p>Telefon: 08405 84149019</p>
                <p>E-Mail: joseph.gala@web.de</p>
            </div>
            <div className={styles.block}>
                <h2>Steuernummer</h2>
                <p>171 / 233 / 50111</p>
            </div>
            <div className={styles.block}>
                <h2>Verantwortlich für den Inhalt gemäß § 55 Abs. 2 RStV</h2>
                <p>Anthony Joseph</p>
                <p>Am Pulverl 14</p>
                <p>85051 Ingolstadt</p>
            </div>
            <div className={styles.block}>
                <h2>Haftungsausschluss</h2>
                <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Die Informationen auf dieser Webseite wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>
            </div>
            <div className={styles.block}>
                <h2>Urheberrecht</h2>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
            </div>
            <div className={styles.block}>
                <h2>Datenschutz</h2>
                <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
            </div>
            <div className={styles.block}>
                <h2>Technische Umsetzung</h2>
                <p>Akos Szabo</p>
                <p>akos.one</p>
                <p>Gabelsbergerstr. 36</p>
                <p>85057 Ingolstadt</p>
                <p>DE368471594</p>
            </div>
            <div className={styles.block}>
                <h2>Rechtswirksamkeit dieses Haftungsausschlusses</h2>
                <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>
            </div>

        </div>
    </div>
  )
}

export default Imprint