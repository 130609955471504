import React from 'react'
import style from './style/entry.module.scss'
import { Link } from 'react-router-dom'
import { IoBatteryFullOutline, IoDiamondOutline, IoPeopleOutline, IoRibbonOutline } from 'react-icons/io5'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import Slider from "react-slick";


export default function Entry() {

  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className={style.container}>
      <div className={style.hero}>
        <Slider {...settings}>
          <div className={style.imageContext}>
            <div className={style.overlay}>
              <h1>Wir bauen Ihren Traumgarten</h1>
              <p>Von der Planung bis zur Umsetzung, wir verwandeln Ihren Garten in eine Oase der Entspannung.</p>
              <Link to='/contact'>Jetzt Anfragen</Link>
            </div>
            <img src='https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.43%20(2).jpeg?alt=media&token=8e9e4018-cac6-4ec7-bc53-fd6a1f658d86' alt='Gartenbau' />
          </div>

          <div className={style.imageContext}>
            <div className={style.overlay}>
              <h1>Fassadenreinigung</h1>
              <p>Sorgfältige und fachmännische Pflege Ihrer Gebäudefassade für langfristige Ästhetik und Schutz.</p>
              <Link to='/contact'>Jetzt Anfragen</Link>
            </div>
            <img src='https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FWhatsApp%20Image%202024-06-08%20at%2015.26.41.jpeg?alt=media&token=70fff292-9d45-4ff1-a69c-453696e6cc58' alt='Gartenbau' />
          </div>

          <div className={style.imageContext}>
            <div className={style.overlay}>
              <h1>Dachreinigung</h1>
              <p>Wir reinigen und pflegen Ihr Dach, um die natürliche Schönheit und Langlebigkeit zu erhalten.</p>
              <Link to='/services/steinreinigung'>Mehr ansehen</Link>
            </div>
            <img src='https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.57.jpeg?alt=media&token=053473a5-a468-4230-9e13-23a6f3c897fa' alt='Gartenbau' />
          </div>
        </Slider>
        
      </div>

      <div className={style.services}>
        <div className={style.content}>
          <h1>Unsere Unternehmenswerte</h1>
          <p>" Grünes Unternehmen schützt Natur mit innovativen Lösungen "</p>
        </div>

        <div className={style.cards}>
          <div className={style.card}>
            <div className={style.icon}>
              <IoDiamondOutline />
            </div>
            <h2>Qualität</h2>
            <p>Unser Anspruch ist höchste Qualität und Kundenzufriedenheit</p>
          </div>
          
          <div className={style.card}>
            <div className={style.icon}>
            <IoBatteryFullOutline />
            </div>
            <h2>Energie</h2>
            <p>Nachhaltigkeit liegt und sehr am Herzen</p>
          </div>

          <div className={style.card}>
            <div className={style.icon}>
              <IoPeopleOutline />
            </div>
            <h2>Team</h2>
            <p>Unser Team aus langjährigen Experten gestaltet kreativ Gärten</p>
          </div>
        </div>
      </div>

      <div className={style.preview}>
        <h1>Was soll es werden</h1>

        <div className={style.images}>
          <div className={style.image} />
          <div className={style.image} />
          <div className={style.image} />
          <div className={style.image} />
        </div>

        <Link to='/services'>Mehr ansehen</Link>
      </div>


      <div className={style.about}>
        <section id='about' />
        <div className={style.left}>
          <h1>Zufriedene Kunden</h1>
          <h2>150+</h2>
          <p>Wir sind ein Team von Experten, die sich auf die Umwandlung von Gärten spezialisiert haben. Wir sind stolz darauf, Ihnen die besten Dienstleistungen zu bieten, die Sie sich wünschen können. Wir sind hier, um Ihren Traumgarten zu schaffen.</p>


        </div>

        <div className={style.right}>
          <div className={style.image} />
        </div>
      </div>


      <div className={style.beforeAfter}>
        <div className={style.header}>
          <h1>Vergleichen Sie selbst</h1>
          <p>Sehen Sie den Unterschied in der Sauberkeit Ihrer Einfahrt nach einer Reinigung.</p>
          <div className={style.buttons}>
            <Link to='/services#3'>Reinigungen</Link>
            <Link to='/contact'>Kontakt</Link>
          </div>
        </div>
        <div className={style.compareWrapper}>
          <ReactCompareSlider className={style.compareSlider}
            itemOne={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FBeforeAfter%2F2024-05-23-17_5536.jpeg?alt=media&token=19c79142-a6de-4e29-a8f4-9bf9b45e1e97" alt="Image one" />}
            itemTwo={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FBeforeAfter%2F2024-05-23-17_5556.jpeg?alt=media&token=f6acc354-c14e-46d1-8709-b72cd6c0e489" alt="Image two" />}
          />
        </div>
      </div>

      <div className={style.contact}>
        <h1>Wir sind hier um Ihnen zu helfen</h1>
        <p>Kontaktieren Sie uns noch heute, um Ihr Projekt zu starten.</p>
        <Link to='/contact'>Kontakt aufnehmen</Link>
      </div>


      <div className={style.faq}>
        <section id='faq' />
        <div className={style.header}>
          <h1>FAQ</h1>
          <p>Die häufigsten Fragen, die wir erhalten</p>
        </div>

        <div className={style.questions}>
          <details>
            <summary>Wie lange dauert die Umsetzung eines Projekts?</summary>
            <div className={style.answer}>
              <p>Die Dauer eines Projekts hängt von der Größe und Komplexität ab. Wir können Ihnen jedoch eine Schätzung geben, sobald wir Ihr Projekt besprochen haben.</p>
            </div>
          </details>

          <details>
            <summary>Was muss ich vorbereiten?</summary>
            <div className={style.answer}>
              <p>Wir benötigen Zugang zu Ihrem Garten und eine Steckdose für unsere Geräte. Wir werden Ihnen jedoch alle notwendigen Informationen geben, sobald wir Ihr Projekt besprochen haben.</p>
            </div>
          </details>
        </div>
      </div>
    </div>
  )
}
