// src/utils/services.ts
export interface Service {
    name: string;
    description: string;
    imageUrl: string;
    keyFeatures: string[];
    keyFeaturesImages: string[];
  }
  
  export const services: Service[] = [
    {
      name: 'fassadenreinigung',
      description: `Durch spezielle Vorbehandlung wird die Fassade sanft gereinigt.
  Dabei wird darauf geachtet, dass die Oberfläche weder beschädigt noch aufgeraut wird.
  Gelöste Verunreinigungen werden mithilfe von Breitstrahldüsen und geringem Wasserdruck schonend abgespült.
  Aufgrund des großen Düsenabstandes bleibt die Fassade unversehrt und wird nicht aufgeraut oder ausgespült.
  Diese sanfte Reinigungsmethode gewährleistet, dass die Fassade in ihrem ursprünglichen Zustand erhalten bleibt,
  während gleichzeitig eine gründliche Reinigung erzielt wird.
  Durch die hohe Tiefenwirkung des angewandten Fassadenschutzes zieht dieser in die Fassadenoberfläche ein und bietet für die nächsten Jahre wirksamen Schutz gegen erneuten Algenbefall.
  Die Kombination aus sanfter Reinigung und tiefenwirksamem Schutz sorgt dafür, dass die Fassade langfristig geschützt
  und in einem gepflegten Zustand bleibt.
  Durch diese Methode wird nicht nur die ästhetische Erscheinung der Fassade verbessert,
  sondern auch ihre Lebensdauer verlängert.
  Ein gut gepflegtes Gebäude bleibt somit länger attraktiv und wertbeständig.`,
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FWhatsApp%20Image%202024-06-08%20at%2015.26.41.jpeg?alt=media&token=70fff292-9d45-4ff1-a69c-453696e6cc58',

      keyFeatures: [
        'Reinigung der Fassade',
        'Tiefenwirkung des angewandten Fassadenschutzes',
        'Gepflegter Zustand der Fassade',
      ],
      keyFeaturesImages: [
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.46%20(1).jpeg?alt=media&token=d18cc775-630d-4a4c-8d2a-d045514156d4',
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.47.jpeg?alt=media&token=fc4dfed5-f36f-4146-8f13-ad7ddfd4b3bf',
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.50.jpeg?alt=media&token=c2c189ef-b4e1-42e1-b4f7-ab80ebe8ea4c'
      ],
    },

    {
      name: 'verfugung',
      description: `Die Verfugung ist ein entscheidender Schritt zur Sicherung und Erhaltung Ihrer Baustruktur. Um eine optimale Haftung der Fugenmasse zu gewährleisten, müssen die zu bearbeitenden Flächen zunächst gründlich gereinigt werden. Eine unzureichend gereinigte Oberfläche kann die Qualität und Langlebigkeit der Fugen erheblich beeinträchtigen.
      Ziel ist es, alle Verunreinigungen wie Moos, Algen und Flechten vollständig zu entfernen, um eine glatte und porenfreie Grundlage für die Verfugung zu schaffen. Der richtige Einsatz von Druck und Winkel bei der Reinigung ist dabei entscheidend, um Beschädigungen des Materials zu vermeiden. Besonders bei älteren Bauwerken ist Sorgfalt gefragt, um die bestehenden Strukturen nicht zu gefährden.`,
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fheader-image.jpg?alt=media&token=44007004-a958-4eb8-a2ad-f270f5f34ecf',

      keyFeatures: [
        'Gründliche Reinigung und Vorbereitung',
        'Effektiver Schutz',
        'Langlebigkeit und Ästhetik',
      ],
      keyFeaturesImages: [
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FWhatsApp%20Image%202024-08-22%20at%2010.09.18%20(2).jpeg?alt=media&token=0a8b095f-b9e9-46b0-914d-402cc0cf317e',
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FWhatsApp%20Image%202024-08-22%20at%2010.09.18%20(3).jpeg?alt=media&token=c35f7baf-c708-48ff-b2d1-c2bdb6f161f4',
      ],
    },
    {
      name: 'steinreinigung',
      description: `Mit unserem bewährten Reinigungsverfahren machen wir deine Pflastersteine innerhalb eines Tages wieder wie neu!
  Wir entfernen nicht nur sichtbare Verunreinigungen wie Algen, Moose und Pilzbefall, sondern auch unsichtbare Rückstände und Spuren.
  Dafür setzen wir biologisch abbaubare Reinigungsmittel ein, die wie eine Desinfektion wirken und die Stabilität deiner Steine erhalten.`,
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FBeforeAfter%2F2024-05-23-17_5556.jpeg?alt=media&token=f6acc354-c14e-46d1-8709-b72cd6c0e489',

      keyFeatures: [
        'Schutz vor Algen, Moose und Pilzbefall',
        'Schutz vor Trockenheit und Schmutz',
        'Reinigung der Einfahrte',
      ],
      keyFeaturesImages: [
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2F2024-05-23-17_5936.jpeg?alt=media&token=e8fe69f4-3f66-481b-81da-b5bca8fe4554',
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FScreenshot%202024-07-21%20at%205.58.31%E2%80%AFPM.png?alt=media&token=56ee5eaa-1c43-4f08-ad87-0e11802f1515',
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FScreenshot%202024-07-21%20at%206.54.51%E2%80%AFPM.png?alt=media&token=772769d5-3e90-4fa8-b5dc-6b67a51c9f8e'
      ],
    },
    {
      name: 'gartenarbeit',
      description: `Durch zahlreiche Projekte wurde eine breite Palette an Erfahrungen gesammelt.
  Das Unternehmen wurde gegründet, welches sich seither auf die Umgestaltung und Neuanlage von Gärten spezialisiert hat.
  Ein erfahrenes Team kümmert sich um die Bepflanzung und Pflege von Grünflächen, verlegt Rollrasen,
  fertigt maßgeschneiderte Sichtschutzelemente sowie Zäune an.
  Besonderer Wert wird auf die Schaffung von Entspannungsoasen gelegt, indem individuelle Teichanlagen und Wasserwelten gestaltet werden,
  die eine Wohlfühlatmosphäre direkt vor der Haustür schaffen.
  Das Ziel ist es, jedem Gartenliebhaber ein maßgeschneidertes, harmonisches und ästhetisch ansprechendes Gartenkonzept zu bieten.
  Falls Unterstützung bei einem Gartenprojekt benötigt wird,
  steht das Team gerne zur Verfügung und freut sich darauf, gemeinsam mit den Kunden deren Traumgarten zu realisieren.`,
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2F2024-05-23_1734337.jpeg?alt=media&token=2f8d39e4-5cb4-47e1-a675-329deab79c26',

      keyFeatures: [
        'Rollrasen verlegung',
        'Sichtschutzelemente',
        'Steinwegverlegung',
        'Vieles mehr',
      ],
      keyFeaturesImages: [
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.42.jpeg?alt=media&token=fe2007b4-f2b3-485b-9ea9-c01511630c8b',
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.43%20(2).jpeg?alt=media&token=8e9e4018-cac6-4ec7-bc53-fd6a1f658d86',
        'https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.43.jpeg?alt=media&token=6169c16a-c37f-439a-9162-af879c33b59a'
      ],
    },
    // Weitere Services hinzufügen
  ];
  