// src/pages/serviceDetail.tsx
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { services } from '../utils/services';
import style from '../pages/style/servicesDetail.module.scss'
import { IoArrowBack, IoCheckmark } from 'react-icons/io5';
import Slider from "react-slick";

export default function ServiceDetail() {
  const { serviceName } = useParams();
  const service = services.find(s => s.name === serviceName);

  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  if (!service) {
    return <div className={style.container} style={{textAlign: 'center'}}>Service not found</div>;
  }

  return (
    <div className={style.container}>
        <Link to="/services/" className={style.backArrow}>
            <IoArrowBack />
        </Link>
        <div className={style.imageWrapper}
            style={{ backgroundImage: `url(${service.imageUrl})` }}>
            <h1>{service.name.replace('-', ' ')}</h1>
        </div>
      <p>{service.description}</p>

      <Link to="/contact">Jetzt anfragen</Link>

      <div className={style.keyFeatures}>
        <div className={style.imageBlock}>
          <Slider {...settings}>
            {service.keyFeaturesImages.map((image, index) => (
              <div key={index}>
                <img src={image} alt={image} />
              </div>
            ))}
          </Slider>
        </div>

        <div className={style.textBlock}>
          <h1>Key Features</h1>
          <h2>Außergewöhnlicher Service, <br/> maßgeschneidert für Sie.</h2>
          <span>Unser Service ist darauf ausgelegt, Ihre Erwartungen zu übertreffen. Von personalisierter Unterstützung bis hin zu modernster Technologie - wir sind für Sie da.</span>
          {service.keyFeatures.map((feature, index) => (
            <p key={index}><IoCheckmark/>{feature}</p>
          ))}
        </div>

      </div>
    </div>
  );
}
