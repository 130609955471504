import React from 'react'
import style from './style/services.module.scss'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import { Link } from 'react-router-dom'
import { IoArrowUpCircle, IoChevronForwardOutline } from 'react-icons/io5'


export default function Services() {  

  return (
    <div className={style.container}>
        <div className={style.heading}>
          <h1>Unsere Dienstleistungen</h1>
        </div>

        <div className={style.wrapper}>
          <div className={style.card}>
            <div className={style.image}>
              <ReactCompareSlider className={style.compareSlider}
                  itemOne={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.51%20(1).jpeg?alt=media&token=6712ddf1-aa2e-423f-a215-b1650c3001fa" alt="Image one" />}
                  itemTwo={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.52.jpeg?alt=media&token=f9d25dae-dce3-4253-9326-3feb31ce4440" alt="Image two" />}
              />
            </div>
            <div className={style.content}>
              <h2>Fassadenreinigung</h2>
              <p>Unser Team von Fachleuten verwendet fortschrittliche Techniken und Materialien, um Ihre Fassade schonend und effektiv ohne Hochdruck zu reinigen. Dieser Ansatz schützt die Bausubstanz und stellt sicher, dass Ihr Gebäude über Jahre hinweg gut aussieht.</p>
              <Link to="/services/fassadenreinigung">Mehr lesen <IoChevronForwardOutline /></Link>
            </div>
          </div>

          <div className={style.card}>
            <div className={style.image}>
              <ReactCompareSlider className={style.compareSlider}
                itemOne={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FBeforeAfter%2FWhatsApp%20Image%202024-08-22%20at%2010.09.18%20(1).jpeg?alt=media&token=56398cb2-acda-4063-b351-bdb6ae79a0c2" alt="Image one" />}
                itemTwo={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FBeforeAfter%2FWhatsApp%20Image%202024-08-22%20at%2010.09.18.jpeg?alt=media&token=a22d2b3d-b31d-47fe-9b3d-0bd1d3716474" alt="Image two" />}
              />
            </div>
            <div className={style.content}>
              <h2>Fugenmittel gegen Unkraut</h2>
              <p>Wir verwenden spezielle Fugenmittel, um das Wachstum von Unkraut zwischen Ihren Steinen zu verhindern.</p>
              <Link to="/services/verfugung">Mehr lesen <IoChevronForwardOutline /></Link>
            </div>
          </div>

          <div className={style.card}>
            <div className={style.image}>
              <ReactCompareSlider className={style.compareSlider}
                  itemOne={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FBeforeAfter%2F2024-05-23-17_5536.jpeg?alt=media&token=19c79142-a6de-4e29-a8f4-9bf9b45e1e97" alt="Image one" />}
                  itemTwo={<ReactCompareSliderImage src="..." srcSet="https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FBeforeAfter%2F2024-05-23-17_5556.jpeg?alt=media&token=f6acc354-c14e-46d1-8709-b72cd6c0e489" alt="Image two" />}
                />
            </div>
            <div className={style.content}>
              <h2>Steinreinigung/Steinpflege</h2>
              <p>Wir reinigen und pflegen Ihre Steine, um ihre natürliche Schönheit und Langlebigkeit zu erhalten.</p>
              <Link to="/services/steinreinigung">Mehr lesen <IoChevronForwardOutline /></Link>
            </div>
          </div>

          <div className={style.card}>
            <div className={style.image}>
            <img src='https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.43%20(1).jpeg?alt=media&token=58d15bee-8b5d-43c4-ae25-4ffa586d7185' alt='product-img' />
            </div>
            <div className={style.content}>
              <h2>Gartenarbeiten</h2>
              <p>Unser Team von Gartenexperten bietet eine Vielzahl von Dienstleistungen an, darunter Sichtschutzzaunbau, Baumschnitt, Rollrasenverlegung und Heckenschnitt.</p>
              <Link to="/services/gartenarbeit">Mehr lesen <IoChevronForwardOutline /></Link>
            </div>
          </div>
        </div>
    </div>
  )
}
