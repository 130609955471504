import React from 'react'
import style from './style/footer.module.scss'
import { NavLink } from 'react-router-dom'

export default function Footer() {
  return (
    <div className={style.footer}>
      <div className={style.wrapper}>
        <div className={style.company}>
            <h1>Fa. Gala-bau Joseph</h1>
            <p>All rights reserved &copy; {new Date().getFullYear()}</p>
        </div>

        <div className={style.content}>
            <NavLink to='/'>Home</NavLink>
            <a href='/#about'>Über uns</a>
            <NavLink to='/services'>Leistungen</NavLink>
            <a href='/#faq'>FAQ</a>
            <NavLink to='/imprint'>Impressum</NavLink>
        </div> 
      </div>

      <h1>Made with ❤️ by <a href="https://akos.one/">akos.one</a></h1>
    </div>
  )
}
